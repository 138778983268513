/* latin-ext */

@font-face {
    font-family: $primary-font;
    font-weight: 400;
    src: url(../fonts/PlusJakartaSans-Regular.ttf) format('truetype');
}


/* latin-ext */

@font-face {
    font-family: $primary-font-bold;
    font-weight: 700;
    src: url(../fonts/PlusJakartaSans-Bold.ttf) format('truetype');
}

@font-face {
    font-family: $primary-font-light;
    font-weight: 300;
    src: url(../fonts/PlusJakartaSans-Light.ttf) format('truetype');
}

@font-face {
    font-family: $primary-font-italic;
    font-weight: 300;
    src: url(../fonts/PlusJakartaSans-Italic.ttf) format('truetype');
}

// APP-7299
$font-stack: $primary-font,
Arial,
sans-serif;
.hg-font {
    font-family: $primary-font !important;
}

.hg-font-bold {
    font-family: $primary-font-bold !important;
}
