* {
    font-size: 100%;
}

body {
    font-family: $font-stack !important;
    font-size: 1rem;
    -webkit-font-smoothing: antialiased !important;
    -moz-osx-font-smoothing: grayscale !important;
    background: $primary-bg-color;
    color: $text-dark;
    height: 100% !important;
    margin: 0;
    padding: 0;
    display: flex !important;
    flex-direction: column !important;
    color: $text-dark !important;
}

html {
    margin: 0;
    padding: 0;
    height: 100% !important;
    font-family: $primary-font;
  }

  //Page Title
h1,
.h1 {
    font-size: 2.25rem;
    font-weight: 500;
    margin: 0;
    padding: 0;
}

//Header / Page Section title
h2,
.h2 {
    font-size: 1.5rem;
    font-weight: 500;
}

//h3. Important Number / Metric
h3,
.h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: $text-dark;
}

//h4. Header
h4,
.h4 {
    font-size: 1.25rem;
    line-height: 2rem;
    color: $text-dark;
}

//h5. Paragraph Header
h5,
.h5 {
    font-size: 1rem;
    line-height: 1.5rem;
    color: $text-dark;
}

h6,
.h6 {
    font-size: 1rem;
    color: $text-dark;
}

p {
    color: $text-dark;
    font-size: 1rem;
    margin: 0;
    padding: 0;
}

.btn-link-primary{
  text-decoration: none !important;
  color: $primary-blue-50;
  &:hover {
    color: $color_steel_blue;
  }
}

.link-nav { 
    text-decoration: none;
    color: $color-navy-blue !important;
    &:hover {
        color: $color-navy-blue !important;
        text-decoration: none;
    }
}
.link-primary {
    text-decoration: none;
    color: $primary-blue-50 !important;
    &:hover {
        color: $color_steel_blue !important;
        text-decoration: none;
    }
    &:active,
    &.active,
    &.focus,
    &:focus {
        text-decoration: none;
        color: $primary-blue-50 !important;
    }
    .mh-material-icons {
        color: $primary-blue-50;
        text-decoration: none;
    }
    .close-button {
        color: $black;
        text-decoration: none;
    }
}

a.link-interation {
    text-decoration: none;
    color: $color_focused !important;
    &.hover,
    &:hover {
        color: $color_focused !important;
        text-decoration: underline !important;
        text-decoration-color: $color_focused;
    }
    &:active,
    &.active,
    &:focus,
    &.focus {
        text-decoration: none !important;
        color: $color_link_interaction_active !important;
    }
}

