/** input
.form-group -->div
.form-control ==> input(text,number text area)
should have label
**/

.form-group {
    label {
        color: $text-dark;
        font-size: .875rem;
    }
}

.form-control {
    &:focus {
        border: 0.063rem solid $primary-blue-50 !important;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
    }
    &::placeholder,
    .select-placeholder {
        color: $color_text_inactive;
        opacity: 1;
        font-weight: normal;
    }
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: $color_text_inactive;
        opacity: 1;
    }
}

.save-loader {
    width: 1.875rem;
    height: 1.875rem;
}

input.form-control,
textarea.form-control,
select.form-control {
    font-size: 1rem;
    text-align: left;
    background: $white;
    border: 0.063rem solid $color_border_inactive !important;
    @include border-radius(0.188rem);
    color: $text_active;
    option {
        color: $text_active;
        &:hover,
        &:focus {
            background-color: $white !important;
            color: $text_active !important;
        }
        &:not(:checked) {
            background-color: $white;
        }
    }
    &:focus {
        background: $color_hawkes;
        border: 0.063rem solid $primary-blue-50;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
        @include border-radius(0.188rem);
        >option:checked {
            background-color: $primary-blue-50 !important;
            color: $pem-template-bg-white !important;
          }
    }
    &[disabled],
    &.disabled,
    option[disabled] {
        background-color: $color_disabled;
        border: solid 1px $color_tiara_approx;
        color: $color_text_inactive;
        opacity: 1;
    }
    input[disabled].control-label.dollar-thick {
        border: 1px solid #c5ced3;
        background-color: #f0f3fa;
        color: #343434;
    }
}

select.form-control,
.selectbox {
    height: auto;
    -webkit-appearance: none;
    -moz-appearance: none;
}

.controls>input {
    &:focus+.control-label,
    +.control-label,
    &:disabled+.control-label {
        float: left;
        padding: 3px 6px 1px;
        font-size: 1.25rem;
        border-radius: 3px 0 0 3px;
        @include border-radius(3px 0 0 3px);
        outline: none;
    }
    &:focus+.control-label {
        background-color: $primary-blue-50;
        border: solid 0.063rem $primary-blue-50;
        color: $white;
    }
    &:disabled+.control-label {
        background-color: $color_disabled;
        border: solid 1px $color_tiara_approx;
        color: $color_oslo_gray_approx !important;
    }
}

.controls>input {
    &:disabled {
        background: $color_disabled;
    }
    +.control-label {
        border: solid 1px $color_tiara_approx;
        background-color: $color_catskill_white_approx;
        color: $text_active;
        font-weight: 600;
    }
    outline: none;
    padding: 5px 5px 4px 10px;
    border: solid 1px $color_tiara_approx;
    border-left: none;
    width: 55%;
    margin-left: -1px;
    line-height: 1.6;
    @include border-radius(0 3px 3px 0);
    &:focus {
        border:  solid $primary-blue-50;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
    }
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder,
    &::placeholder {
        color: $color_text_inactive;
        opacity: 1;
    }
}

textarea {
    resize: none;
}


/* warning css*/

.form-group.has-warning {
    label,
    i {
        color: $black;
    }
    &.error-text,
    small.error-text,
    p.error-text,
    span.error-text,
    div.error-text {
        color: $secondary-red-80;
        font-size: .75rem;
        font-style: italic;
        font-family: $primary-font-italic;
    }
    .form-control,
    input {
        background: $white;
        border: 0.063rem solid $secondary-red-80;
        @include border-radius(0.125rem);
        &:focus {
            border: solid 0.063rem $secondary-red-80;
        }
    }
    .control-label.dollar-thick {
        background-color: $secondary-red-80;
        color: white;
        border: 0.063rem solid $secondary-red-80;
    }
    .warning-image {
        font-size: 1.25rem !important;
        color: $secondary-red-80;
        position: relative;
        top: -3px !important;
        right: 0px !important;
    }
    .warning-image-number-inline {
        $font-size: 1.25rem;
        font-size: $font-size;
        color: $secondary-red-80;
        position: absolute;
        top: 50%;
        margin-top: -(calc($font-size/2));
        right: 0;
        margin-right: 0.5rem;
        overflow: visible;
    }
    .form-control-number-with-icon {
        padding-right: 2rem;
    }
    .dropdown-menu {
        margin-top: -22px !important;
    }
}


/* warning css Ends */


/*Success CSS */

.has-success .form-control {
    border: solid 1px $color_valid;
    &:focus {
        border: solid 1px $color_valid;
        box-shadow: inset 0 1px 3px 0 $black_16;
    }
    &.material-icon {
        color: $color_valid;
    }
}

.success-image {
    color: $color_valid;
}


/* input end*/


/*  checkbox/Radio  */

.radio,
.checkbox {
    margin: 10px;
}

[type="checkbox"]:checked,
[type="checkbox"]:not(:checked),
[type="radio"]:checked,
[type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
}

[type="checkbox"]:checked+label,
[type="checkbox"]:not(:checked)+label {
    position: relative;
    padding-left: 2.3rem;
    cursor: pointer;
    line-height: 1.72;
    padding-top: 0.1rem;
}

[type="radio"]:checked+label:before {
    background: $primary-blue-50;
}

[type="checkbox"]:checked+label:before,
[type="checkbox"]:not(:checked)+label:before {
    content: '';
    position: absolute;
    left: 0;
    top: -2px;
    width: 1.5rem;
    height: 1.5rem;
    outline: 1px solid $warmgray-25;
}

[type="checkbox"]:checked+label:before {
    outline: 0.063rem solid $primary-blue-50;
}

[type="checkbox"]:checked+label:after,
[type="checkbox"]:not(:checked)+label:after {
    content: '';
    position: absolute;
    top: 2px;
    left: 4px;
    font-size: 1.3em;
    line-height: 0.8;
}

[type="checkbox"]:not(:checked)+label:after {
    opacity: 1;
    transform: scale(1);
    border: 8px solid $lightseashell;
}

[type="checkbox"]:checked+label:after {
    opacity: 1;
    transform: scale(1);
    border: 0.5rem solid $primary-blue-50;
}

[type="checkbox"]:focus+label::before {
    @include form-focus();
}


/* disabled checkbox/radio */

[type="checkbox"]:disabled:checked+label:before,
[type="checkbox"]:disabled:not(:checked)+label:before,
[type="radio"]:disabled:checked+label:before,
[type="radio"]:disabled:not(:checked)+label:before {
    box-shadow: none;
    border-color: $color_disabled;
    background-color: $color_alto_approx;
}

[type="checkbox"]:disabled:checked+label:after,
[type="radio"]:disabled:checked+label:after {
    color: $color_mountain_mist_approx;
}

[type="checkbox"]:disabled+label,
[type="radio"]:disabled+label {
    color: $color_silver_chalice_approx;
}

input[type="radio"] {
    opacity: 0;
    -moz-opacity: 0;
    -webkit-opacity: 0;
    -o-opacity: 0;
    margin-left: 25px;
}

input[type="radio"]+label {
    position: relative;
    padding: 0;
    font-size: 16px;
    line-height: 25px;
    margin: 0 0 10px 0;
}

input[type="radio"]+label:before {
    content: "";
    display: inline-block;
    position: relative;
    top: 7px;
    height: 24px;
    width: 24px;
    margin-right: 10px;
    background-color: $seashell;
    border: 1px solid $warmgray-25;
    box-shadow: inset 0px 0px 0px 4px white;
    -webkit-box-shadow: inset 0px 0px 0px 4px white;
    -moz-box-shadow: inset 0px 0px 0px 4px white;
    -o-box-shadow: inset 0px 0px 0px 4px white;
    @include border-radius( 12px);
}

input[type="radio"]:checked+label:before {
    background: $primary-blue-50;
    border: 0.063rem solid $primary-blue-50;
}


/*  checkbox/Radio  Ends */


/* Campaign togglebutton */

%switch-basic-struct {
    content: "";
    display: inline-block;
    width: 48px;
    height: 25px;
    background-color: $white;
    @include border-radius( 0.938rem);
    margin-right: 0.938rem;
    transition: background 0.3s ease;
    vertical-align: middle;
    border: 1px solid $warmgray-25;
}


/* Toggle Button */

.toggle-button {
    vertical-align: middle;
    display: inline-block;
    padding-left: 10px;
    user-select: none;
    padding-bottom: 3px;
    label {
        user-select: none;
        cursor: pointer;
        color: $black_26;
        input[type=checkbox] {
            opacity: 0;
            width: 0;
            height: 0;
            &:checked+.toggle {
                background-color: $white;
                &:after {
                    left: 23px;
                    background-color: $primary-blue-50;
                    border-color: $primary-blue-50;
                }
            }
            &:focus+.toggle {
                @include form-focus();
            }
        }
        .toggle {
            text-align: left;
            @extend %switch-basic-struct;
            &:after {
                content: "";
                display: inline-block;
                width: 25px;
                height: 25px;
                background-color: $seashell;
                border: 1px solid $warmgray-25;
                @include border-radius( 20px);
                position: relative;
                box-shadow: inset 0 0 0 4px $white;
                -webkit-box-shadow: inset 0 0 0 4px $white;
                -moz-box-shadow: inset 0 0 0 4px $white;
                -o-box-shadow: inset 0 0 0 4px $white;
                left: -1px;
                top: -1px;
                transition: left 0.1s ease, background 0.1s ease, box-shadow 0.1s ease;
            }
        }
        input[type=checkbox][disabled] {
            +.toggle {
                @extend %switch-basic-struct;
                &:after {
                    background-color: $color_silver_approx;
                }
                &:active:after {
                    box-shadow: 0 1px 3px 1px $black_40, 0 0 0 15px $black_10;
                }
            }
            &:checked+.toggle:after {
                background-color: $color_silver_approx;
            }
        }
    }
    input {
        user-select: none;
    }
    .toggle {
        user-select: none;
    }
}

.form-group.is-focused .togglebutton label {
    color: $black_26;
    &:hover {
        color: $black_54;
    }
    &:focus {
        color: $black_54;
    }
}

fieldset[disabled] .form-group.is-focused .togglebutton label {
    color: $black_26;
}

.is-disabled,
.disabled {
    color: $text-gray_chalice;
    pointer-events: none;
    cursor: none;
}


/* slider component 
For Tablets, iPads (Portrait and Landscape) */
@media screen and (min-width: 768px) and (max-width: 1024px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(175px) !important;
            }
        }
    }
}
/* For Tablets, iPads (Portrait) */
@media screen and (min-width: 768px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(175px) !important;
            }
        }
    }
}
/* For Tablets, iPads, Laptops (Landscape) */
@media screen and (min-width: 1024px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(260px) !important;
            }
        }
    }
}
/* For Desktops and Laptops */
@media screen and (min-width: 1224px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(330px) !important;
            }
        }
    }
}
@media screen and (min-width: 1440px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(360px) !important;
            }
        }
    }
}
/* For Large screens */
@media screen and (min-width: 1824px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(500px) !important;
            }
        }
    }
}
@media screen and (min-width: 2560px){
    .rzslider {
        ul {
            li:last-child {
                transform: translateX(700px) !important;
            }
        }
    }
}
.rzslider {
    margin: 1.625rem 0 0.9375rem;
    /*override default to match with Zeplin*/
    ul {
        li:last-child {
            transform: translateX(280px) !important;
            margin-left: 0.825rem;
        }
        li:last-child>span {
            left: 0.5rem;
        }
    }
    .rz-bar {
        height: 0.6875rem;
        @include border-radius( 0.3125rem);
        background: $color_geyser_approx;
    }
    .rz-bar-wrapper {
        padding-top: 0;
        margin-top: 0;
    }
    .rz-tick {
        background: inherit;
        margin-left: 0;
        left: 0.3125rem;
        height: 0.5rem;
        &.rz-selected {
            background: inherit;
        }
    }
    .rz-selection {
        background: $slider-bar;
    }
    .rz-bubble {
        font-size: 0.75rem;
        font-weight: bold;
        color: $text-dark;
        bottom: 0.5rem;
        padding: 0.0625rem 0.25rem;
    }
    .rz-pointer {
        top: -0.25rem;
        width: 1.25rem;
        height: 1.25rem;
        background-color: $slider-pointer;
        @include border-radius( 1rem);
        &:after {
            top: 0.3125rem;
            left: 0.3125rem;
            width: 0;
            height: 0;
            background: transparent;
        }
    }
    .rz-ticks-values-under .rz-tick-value {
        font-size: 0.75rem;
        color: $text-dark;
        opacity: 0.7;
    }
}

select::-ms-expand {
    display: none;
}

*::-ms-backdrop,
textarea {
    overflow: hidden;
}

input~.mh-material-icons.form-control-feedback {
    color: $ghost_grey;
}

i.mh-material-icons.more-info-icon {
    color: $primary-blue-50;
}
