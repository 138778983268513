.select-custom {
    border-radius: 0.188rem;
    position: relative;
    select {
        padding-right: 1.875rem;
        @supports (-moz-appearance:none) {
            padding-right: 2.375rem;
        }
    }
    &>i {
        position: absolute;
        top: calc(50% - 1em);
        padding: 0.5rem;
        pointer-events: none;
        right: 0;
    }
}
