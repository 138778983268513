@media (min-width: 576px) {
    .modal-dialog {
        max-width: 37.5rem;
        margin: 1.75rem auto;
        top: 20%;
    }
    .modal-content {
        -webkit-box-shadow: $box-shadow-popup-grey-70;
        box-shadow: $box-shadow-popup-grey-70;
        @include border-radius(0px);
        color: $text-dark;
    }
    .modal-sm {
        max-width: 26.06rem;
        margin-top: 10rem;
        @include border-radius(3px);
        background-color: $color_romance_approx;
        box-shadow: $box-shadow-popup-grey-70;
        color: $text-dark;
    }
    .modal-md {
        max-width: 37.5rem;
    }
}

@media (min-width: 992px) {
    .modal-lg {
        max-width: 56.25rem;
        color: $text-dark;
    }
}

.modal-content {
    @include border-radius(2px);
    color: $text-dark;
    .modal-body {
        padding-left: 1.15rem;
        padding-top: 0;
        h4 {
            font-size: 1.25rem;
            font-weight: bold;
            color: $text_active;
        }
        .table {
            th,
            tbody,
            span,
            p {
                color: $text_active;
            }
        }
    }
    .modal-header {
        border-bottom: none;
        color: $text-dark;
        h3 {
            font-size: 1.5em;
            font-weight: bold;
            color: $text-dark;
        }
    }
    .modal-footer {
        border-top: none;
    }
    .modal-divider {
        border: 1px solid $color-light-grey;
    }
}

.modal-backdrop-opacity {
    opacity: 15% !important;
}

.close-button {
    cursor: pointer;
    color: $text-dark;
}

.modal-header {
    &>.btn-close {
        &:hover,
        &:active,
        &:focus {
            border: none;
            box-shadow: none;
        }
    }
}

.alert-warning {
    background-color: $alert-warning-background-color;
}

.modal-backdrop.modal-backdrop-opacity.fade.show {
    z-index: 1050 !important;
}

.session-notifier-modal{
    z-index: 1070 !important;
}