/*
 * Patient Engagement Solutions Color System
 * source: https://www.figma.com/file/Lu88Lo49fvHlkG9qDh1SfC/_DesignSystem?node-id=0%3A1
 */

// PRIMARY COLORS
$primary-blue-90: #020249;
$primary-blue-80: #02126F;
$primary-blue-70: #022194;
$primary-blue-60: #0231BA;
$primary-blue-50: #132D77;
$primary-blue-40: $primary-blue-50;
$primary-blue-30: #49A6FD;
$primary-blue-20: #8FCEFD;
$primary-blue-10: #D2ECF9;
$primary-blue-sapphire: $primary-blue-50;
$primary-green-90: #003D4C;
$primary-green-80: #0A5B6A;
$primary-green-70: #157A87;
$primary-green-60: #1F98A4;
$primary-green-50: #29B6C2;
$primary-green-40: #4DC7D1;
$primary-green-30: #71D8E1;
$primary-green-20: #95E8F0;
$primary-green-10: #B9F9FF;
$primary-green-legend: #3db98c;
$primary-yellow-legend: #ffc74f;
// SECONDARY COLORS
$secondary-purple-90: #450277;
$secondary-purple-80: #61068E;
$secondary-purple-70: #7D0AA6;
$secondary-purple-60: #990DBD;
$secondary-purple-50: #B511D4;
$secondary-purple-40: #C035DF;
$secondary-purple-30: #CC58EA;
$secondary-purple-20: #D77CF4;
$secondary-purple-10: #E29FFF;
$secondary-green-90: #2C5B59;
$secondary-green-80: #216E69;
$secondary-green-70: #168179;
$secondary-green-60: #0B9389;
$secondary-green-50: #00A699;
$secondary-green-40: #1CB8A3;
$secondary-green-30: #46D3B2;
$secondary-green-20: #70EFC1;
$secondary-green-10: #B7F7DF;
$secondary-red-90: #B33926;
$secondary-red-80: #C41B07;
$secondary-red-70: #D7300D;
$secondary-red-60: #EA4614;
$secondary-red-50: #FD5B1A;
$secondary-red-40: #FD723B;
$secondary-red-30: #FE8A5B;
$secondary-red-20: #FEA17C;
$secondary-red-10: #FEB89C;
$secondary-red-11: #fee1dc;
$secondary-red-danger: #b33927;
$secondary-orange-90: #D76A00;
$secondary-orange-80: #E17A08;
$secondary-orange-70: #EB8B11;
$secondary-orange-60: #F59B19;
$secondary-orange-50: #FFAB21;
$secondary-orange-40: #FFB639;
$secondary-orange-30: #FFC152;
$secondary-orange-20: #FFCB6A;
$secondary-orange-10: #FFD682;
// NEUTRAL COLORS
$neutral-90: #343437;
$neutral-80: #4C4C4F;
$neutral-70: #656567;
$neutral-60: #7D7D7F;
$neutral-50: #969697;
$neutral-40: #AEAEAF;
$neutral-30: #C6C6C7;
$neutral-20: #DFDFDF;
$neutral-10: #F7F7F7;
$neutral-11: #e7e7e7;
$neutral-09: #E7E7E7;
// TEXT COLORS
$text-dark: #4A4947;
$text_grey: #626366;
$text-light: #9B9B9B;
$text-gray_chalice:#9FA1A2;
$text-dark-shark: #212529;
$text-storm-grey: #757679;
// PEM Angular Template Styles
$pem-template-bg-dark: #1B5162;
$pem-template-bg-light: #F9FAFD;
$pem-template-bg-white: #FFFFFF;
$pem-template-text-action: $primary-blue-50;
$pem-template-text-menu: $neutral-90;
$pem-template-text-over-dark: #FFFFFF;
$pem-template-link-over-dark: #CDECEB;
//colors
$calendar-background:#fafafa;
$white: #ffffff;
$black: #000;
$black_10: rgba(0, 0, 0, 0.1);
$black_15: rgba(0, 0, 0, 0.15);
$black_25: hsla(0, 0%, 0%, 0.25);
$black_26: rgba(0, 0, 0, 0.26);
$black_40: rgba(0, 0, 0, 0.4);
$black_50: rgba(0, 0, 0, 0.5);
$black_54:rgba(0, 0, 0, 0.54);
$color_black_abbey: #444548;
$color_bg: #F9FAFD;
$color-asterixs-red:#d0021b;
$color_tags_background: #74e5d1;
$color_test_inactive: #bbbbbb;
$color_hawkes:#e9f0fd;
$color_disabled:#F7F7F7;
$color_tiara_approx:#c5ced3;
$color_oslo_gray_approx:#888b8d;
$color_catskill_white_approx: #f0f3fa;
$color_valid: #0ba131;
$black_16: rgba(0, 0, 0, 0.16);
$color_paragraph: #424242;
$color_star_dust_approx: #9d9c9c;
$warmgray-25: #9fa5ab;
$lightseashell: #f9f8f6;
$color_alto_approx: #ddd;
$color_mountain_mist_approx:#999;
$color_silver_chalice_approx: #aaa;
$seashell: #f1f1f1;
$color_silver_approx: #bdbdbd;
$color_geyser_approx: #dbdfe7;
$color-blue: #0000ff;
$slider-bar:#0000bf;
$slider-pointer: $primary-blue-50;
$color_gravel_approx:#4a4a4a;
$ghost_grey:#dddddd;
$hover_groups:#e3e6ee;
$color_desert_storm_approx: #f8f8f8;
$color_forest_green_approx1: #0ba131;
$color_mine_shaft_approx: #333333;
$color_tags_background: #74e5d1;
$primary-bg-color: #F9FAFD;
$color_focused: #0202ea;
$color_link_interaction_active:#0303b4;
$color_alert_shadow:rgba(32, 148, 138, 0.08);
$option_menu_hg:#ecf7f6;
$color_nobel_approx:#b3b3b3;
$color_dim_grey: #737373;
$color_storm_dust_approx: #666666;
$color_unauthorized_grey: #636366;
$color_romance_approx:#fdfdfd;
$alert-warning-background-color: rgba($color_bg, 0.3);
$color_error_background : rgba(235,238,244,0.7);
$color_text_inactive: #bbbbbb;
$color_scarpa_flow_approx: #53565a;
$color-light-grey: #DDDBDA;
$color-gray: #f0f0f0;
$color_profile_background_grey:  #979797;
$color_steel_blue: #2876BF;
$color_login_error_background: #FFA99B;
$color_login_border: #E64A31;
$color_border_inactive:#d5eff7;
$spinner_background: #F3F3F3;
$color_fuscous_gray_approx: #52514C;
$color_sky_blue: #51a0d6;
$color_light_cyan: #daf4f5;
$color_success_badge_txt: #174452;
$color_danger_badge_txt: #662116;
$color_yellow_warning: #fef5d3;
$color_table_border: #dee2e6;
$calendar-background:#fafafa;
$dark-slate-gray:#4A4A4D; 
$text_greyish_black: #4A4A4D;
$color-teal-green: #B9E4F1;
$color-navy-blue: #2363AD;
$color_success_notifier_border: #41B8B2;
$color_success_notifier_bg_color: rgba(218, 244, 245, 0.35);
$color_dark_grayish_orange: #4a4947;
$color_light_greenish_blue: #6cdede;
$color_dark_grayish_brown: #4a4947;
$color_light_gray:rgba(0,0,0,0.2);
$color_light_green_blue: rgba(32, 148, 138, 0.08);



// Font Variables
$icomoon: "icomoon";
$material-icons: "material-icons";
$primary-font: "plusjakartasans";
$primary-font-bold: "plusjakartasans-bold";
$primary-font-light: "plusjakartasans-light";
$primary-font-italic: "plusjakartasans-italic";

/* box-shadow for popover*/

$box-shadow-popup-grey-70: 0 4px 10px black-color(70);
$box-shadow-popup-grey-30: 0 1px 3px 0 black-color(30);
$box-shadow-popup-grey-22: 0px 4px 10px 0px black-color(22);

/* box-shadow for card tiles*/
$box-shadow-tile: 0 0.375rem 0.625rem 0 rgba(0, 0, 0, 0.15);


/* legend bullet for chart  */

$legend_bullet_size: 0.75rem;
$chart_title_size: 1.25rem;
$muted_text: #585b62;
$border_group_title: #d2d5dc;

/* Form-controls */

$text_active: $text-dark;

/* Bootstrap overrides */

$modal-content-bg: #FCFCFC;

/* Form-controls */

$text_active: $text-dark;

/* Bootstrap overrides */

$modal-content-bg: #FCFCFC;
/*class for border radius dynamic*/
/* border radius for all browsers*/
@mixin border-radius($radius) {
    -webkit-border-radius: $radius;
    -moz-border-radius: $radius;
    -ms-border-radius: $radius;
    border-radius: $radius;
}

@mixin form-focus {
    border: 1px solid $primary-blue-50 !important;
    box-shadow: 0 1px 3px 0 #c5ced3, inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

@for $value from 1 through 100 {
    $radius: $value*1px;

    .border-radius-#{$value} {
        @include border-radius($radius);
    }
}
.form-check-input {
    height: 20px !important;
    width: 35px !important;
&:checked {
    background-color: $primary-blue-50 !important;
    border-color: $primary-blue-50 !important;
    
}}

.form-control {
    &:focus {
        border: 0.063rem solid $primary-blue-50 !important;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
    }
    &::placeholder,
    .select-placeholder {
        color: $color_text_inactive;
        opacity: 1;
        font-weight: normal;
    }
    &::-webkit-input-placeholder,
    &::-moz-placeholder,
    &:-ms-input-placeholder,
    &::-ms-input-placeholder {
        color: $color_text_inactive;
        opacity: 1;
    }
}

input[type='checkbox']:not(.form-check-input),
input.form-control,
textarea.form-control,
select.form-control {
    font-size: 1rem;
    text-align: left;
    background: $white;
    border: 0.063rem solid $color_border_inactive !important;
    @include border-radius(0.188rem);
    color: $text_active;
    option {
        color: $text_active;
        &:hover,
        &:focus {
            background-color: $white !important;
            color: $text_active !important;
        }
        &:not(:checked) {
            background-color: $white;
        }
    }
    &:focus {
        background: $color_hawkes;
        border: 0.063rem solid $primary-blue-50;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 0.063rem 0.188rem 0 rgba(0, 0, 0, 0.16);
        @include border-radius(0.188rem);
        >option:checked {
            background-color: $primary-blue-50 !important;
            color: $pem-template-bg-white !important;
          }
    }
    &[disabled],
    &.disabled,
    option[disabled] {
        background-color: $color_disabled;
        border: solid 1px $color_tiara_approx;
        color: $color_text_inactive;
        opacity: 1;
    }
    input[disabled].control-label.dollar-thick {
        border: 1px solid #c5ced3;
        background-color: #f0f3fa;
        color: #343434;
    }
}
.dropdown-btn span {
    color: $color_text_inactive !important;
    opacity: 1;
}
.selected-item-container span {
    background-color: #6cdede !important;
    color: #4a4947 !important;
    border: 1px #6cdede !important;
    box-shadow: none !important;
    padding: 3px !important;
    
    a {
        color: #4a4947 !important;
        padding-right:5px;
    }

}



/* min height for containers*/

@mixin min-height($value) {
    min-height: $value;
}


/* common style for Map Containers */

@mixin container-style {
    z-index: 999;
    box-shadow: $box-shadow-popup-grey-30;
    border: solid 0.0625rem #dee2e3;
    @include border-radius(0.1875rem);
}

@mixin form-focus {
    border: 0.063rem solid #132D77 !important;
     box-shadow: 0 1px 3px 0 #c5ced3, inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
}

@mixin aspect-ratio($width, $height, $content_class) {
    position: relative;
    &:before {
        display: block;
        content: "";
        width: 100%;
        padding-left: ($width / $height) * 50%;
        padding-right: ($width / $height) * 50%;
    }
    @each $class in $content_class {
        >.#{$class} {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
        }
    }
}