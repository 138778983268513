/**** Common Classes***/


/* dynamic width generator */

@for $value from 1 through 100 {
    $width: percentage(calc($value/100));

    .width-#{$value} {
        width: $width;
    }
}


/* dynamic font size generator */

@for $value from 1 through 100 {

    // $font-size: $value*0.0625rem;
    .font-size-#{$value} {
        font-size: $value*0.0625rem !important;
    }
}


/*class for border radius dynamic*/

@for $value from 1 through 100 {
    $radius: $value*1px;

    .border-radius-#{$value} {
        @include border-radius($radius);
    }
}


/*class for min height dynamic*/

@for $value from 1 through 500 {
    $minHeight: $value*1px;

    .min-height-#{$value} {
        min-height: $minHeight;
    }
}


/*class for height dynamic*/

@for $value from 1 through 500 {
    $height: $value*1px;

    .height-#{$value} {
        height: $height;
    }
}

/*class for dynamic min height in vh*/

@for $value from 1 through 100 {
    $minHeightVh: $value*1vh;

    .min-height-vh-#{$value} {
        min-height: $minHeightVh;
    }
}

@function black-color($value) {
    $text-color: rgba(0, 0, 0, $value*0.01);
    @return $text-color;
}


/*class for padding top dynamic*/

@for $value from 1 through 20 {
    $paddingTop: $value*1px;

    .p-top-#{$value} {
        padding-top: $paddingTop !important;
    }
}


/*class for padding left dynamic*/

@for $value from 1 through 40 {
    $paddingLeft: $value*1px;

    .padding-left-#{$value} {
        padding-left: $paddingLeft !important;
    }
}


/*class for padding right dynamic*/

@for $value from 1 through 40 {
    $paddingRight: $value*1px;

    .padding-right-#{$value} {
        padding-right: $paddingRight !important;
    }
}


/*class for margin top dynamic*/

@for $value from 1 through 20 {
    $marginTop: $value*1px;

    .mtop-#{$value} {
        margin-top: $marginTop !important;
    }
}


/*class for margin bottom dynamic*/

@for $value from 1 through 20 {
    $marginBottom: $value*1px;

    .mbotttom-#{$value} {
        margin-bottom: $marginBottom !important;
    }
}


/*class for line height dynamic*/

@for $value from 0 through 40 {
    $lineHeight: $value*1px;

    .lnht-#{$value} {
        line-height: $lineHeight !important;
    }
}


/*class for width Adjust in percent dynamic*/

@for $value from 1 through 1000 {
    $widthVal: $value*1%;

    .width-adjust-#{$value} {
        max-width: $widthVal !important;
    }
}

.asteric {
    color: $color-asterixs-red !important;
}

.text-noselect {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                    supported by Chrome and Opera */
}

.shadow-1 {
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .25);
}

.line-height-1 {
    line-height: 1 !important;
}

.line-height-125 {
    line-height: 1.25 !important;
}

.line-height-075 {
    line-height: 0.75 !important;
}

.line-height-1775 {
    line-height: 1.175 !important;
}

.table-fixed {
    table-layout: fixed !important;
}

.animate-show-hide.ng-hide {
    opacity: 0;
}

.animate-show-hide.ng-hide-add,
.animate-show-hide.ng-hide-remove {
    transition: all linear 2s;
}

.upload-progress {
    height: 0.5rem !important;

    .progress-bar {
        background-color: $primary-blue-50 !important;
    }
}

.transition-all {
    transition: all .3s ease-in-out !important;
}

.rotate-180 {
    transform: rotate(180deg);
}

.disable-click {
    pointer-events: none;
}

.enable-click {
    pointer-events: auto;
}

.block-with-text {
    position: relative;
    line-height: 1.2em;
    text-align: justify;
    margin-right: -1em;
    padding-right: 1em;
}

.position-relative {
    position: relative;
}

.jiggle {
    animation: shake 0.92s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
    transform: translate3d(0, 0, 0);
    backface-visibility: hidden;
    perspective: 6.25rem;
}

@keyframes shake {

    10%,
    90% {
        transform: translate3d(-.125rem, 0, 0);
    }

    20%,
    80% {
        transform: translate3d(.25rem, 0, 0);
    }

    30%,
    50%,
    70% {
        transform: translate3d(-.5rem, 0, 0);
    }

    40%,
    60% {
        transform: translate3d(.5rem, 0, 0);
    }
}

.number-circle {
    border-radius: 50%;
    padding: 3px;
    color: $white;
    text-align: center;
    width: 24px;
    height: 24px;
    background: $color_tags_background;
}

textarea+.loader,
input+.loader {
    width: 1.25em;
    height: 1.25em;
    top: .5em;
    right: .313em;
}

.green {
    color: $primary-green-legend;
}
.yellow {
    color: $primary-yellow-legend;
}