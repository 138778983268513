.tooltip {
    font-family: $primary-font;
    .tooltip-inner {
        color: $text_active;
        background-color: $white;
        border: solid 0.063rem $color_tiara_approx;
        padding: 0.5rem;
        text-align: center;
        word-wrap: break-word;
    }
}

.bs-tooltip-top .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="top"] .tooltip-arrow::before {
    border-top-color: $color_tiara_approx !important;
}

.bs-tooltip-end .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="right"] .tooltip-arrow::before {
    border-right-color: $color_tiara_approx !important;
}

.bs-tooltip-bottom .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .tooltip-arrow::before {
    border-bottom-color: $color_tiara_approx !important;
}

.bs-tooltip-start .tooltip-arrow::before,
.bs-tooltip-auto[x-placement^="left"] .tooltip-arrow::before {
    border-left-color: $color_tiara_approx !important;
}