/*.btn class for all the bootstrap button features
.btn-sm - for buttons with .75rem text\
btn will take size of the parent it is contained in */

.btn {
    padding: 0.344rem 1.25rem;
    font-size: 0.875rem;
    font-weight: 700;
    max-height: 2.125rem;
}

.btn-sm {
    font-size: .75rem;
}

button,
.btn {
    &:focus,
    &.focus,
    &:active,
    &.active,
    &:hover {
        border: $primary-blue-50 1px solid;
        box-shadow: 0 0.063rem 0.188rem 0 $neutral-10, inset 0 1px 3px 0 rgba(0, 0, 0, 0.16);
    }
}

//override the bootstrap class to suit our application. should be with .btn class
.btn-primary {
  color: $pem-template-text-over-dark;
  background-color: $primary-blue-sapphire !important;
  border: $primary-blue-sapphire 0.063rem solid;
  @include border-radius(0.25rem);
  box-shadow: none !important;
  font-weight: 700;
  &.focus,
  &:active,
  &.active,
  &:focus {
      color:$pem-template-bg-white;
      background-color: $primary-blue-sapphire !important;
      border: $primary-blue-sapphire 0.063rem solid !important
  }
  &:hover {
      color: $pem-template-bg-white;
      background-color: $color_steel_blue !important;
      border: $color_steel_blue 0.063rem solid !important;
      box-shadow: none !important;
  }
  .mh-material-icons {
      color: $white;
  }
  &.disabled,
  &:disabled {
      opacity: .5 !important;
      pointer-events: none;
      background-color: $color_disabled !important;
      color: $text-gray_chalice !important;
      border: $color_disabled 0.063rem solid !important;
      pointer-events: none;
      font-weight: 700;
  }
}

.btn-tertiary {
    @extend .btn-primary;
    background-color: $primary-green-40 !important;
    border: $primary-green-40 0.063rem solid;
    &:hover {
        border: $primary-green-40 0.063rem solid !important;
    }
}

//hollow  version of primary. should be with .btn class
.btn-primary-hollow {
  color: $primary-blue-50;
  background-color: $pem-template-text-over-dark;
  border: $primary-blue-50 0.063rem solid;
  @include border-radius(0.25rem);
  &:focus,
  &:active,
  &.active,
  &.focus,
  &:hover {
      color: $primary-blue-50;
      background-color: $pem-template-text-over-dark;
      border: $primary-blue-50 0.063rem solid;
      box-shadow: none;
  }
  &:hover {
      color: $white !important;
      background-color: $primary-blue-50 !important;
      border: $primary-blue-50 0.063rem solid !important;
      box-shadow: none;
  }
  &.disabled,
  &:disabled {
      background-color: $color_disabled !important;
      color: $text-gray_chalice !important;
      border: $color_disabled 0.063rem solid !important;
      pointer-events: none;
      font-weight: 700;
  }
}

.btn-secondary {
    background-color: $primary-blue-40 !important;
    border: $primary-blue-40 0.063rem solid !important;
    box-shadow: none !important;
    &:hover {
        border: $primary-blue-40 0.063rem solid !important;
    }
}

.btn-secondary-hollow {
    color: $primary-blue-40;
    background-color: $white;
    border: $primary-blue-40 0.063rem solid;
    margin-bottom: 13.1px;
    @include border-radius(0.25rem);
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:hover {
        color: $primary-blue-40;
        background-color: $white;
        border: $primary-blue-40 0.063rem solid;
        box-shadow: none;
        outline: none !important;
    }
}

.btn-secondary-hollow-thin {
    border: 0.063rem solid $primary-blue-40!important;
    @include border-radius(0.25rem);
    &:focus,
    &:active,
    &.active,
    &.focus,
    &:hover {
        border: 0.063rem solid $primary-blue-40!important;
        outline: none !important;
    }
}

.btn-link {
  border: none !important;
  text-decoration: none !important;
  color: $primary-blue-50;
  &:focus,
  &:active,
  &.active,
  &.focus,
  &:hover {
      color: $primary-blue-50;
      border: none;
      box-shadow: none !important;
  }
  &.disabled,
  &:disabled {
      background-color: $color_disabled !important;
      color: $text-gray_chalice !important;
      border: $color_disabled 0.063rem solid !important;
      pointer-events: none;
      font-weight: 700;
  }
}

.btn-danger {
    background-color: $secondary-red-danger !important;
    border: $secondary-red-danger 0.063rem solid !important;
    @include border-radius(0.25rem);
    &:hover {
        border: $secondary-red-danger 0.063rem solid !important;
    }
    &.disabled,
    &:disabled {
        background-color: $color_disabled !important;
        color: $text-gray_chalice !important;
        border: $color_disabled 0.063rem solid !important;
        pointer-events: none;
        font-weight: 700;
    }
}

.btn-light {
    background-color: $white !important;
    border: $white 0.063rem solid;
    @include border-radius(0.25rem);
    box-shadow: none;
    color: $text-gray_chalice;
    &:hover {
        box-shadow: none;
        border: $white 0.063rem solid;
    }
}

//override the bootstrap class to disable elements. can be added to any form element
.disabled {
  opacity: .5 !important;
  pointer-events: none;
  font-weight: 700;
  &>a {
      &.link-primary {
          color: $text-gray_chalice !important;
      }
  }

}

.disabled-opacity-1 {
    @extend .disabled;
    opacity: 1 !important;
}
