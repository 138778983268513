@import 'mh_color_system';
@import 'mh_button';

.error-message-container {
  height: 80vh;
  background-color: $white;
}

.add-shadow {
  box-shadow: 0 2px 4px 0 $black_25;
}

.min-height {
  min-height: 10rem;
  color: $text-light;
}

.wrapper-content {
  // leaving nav fixed space
  margin-top: 5.063rem;
}

#loading-bar-spinner {
  display: none;
}

@media (min-width: 992px) {
  .vh-lg-100 {
    min-height: 100vh;
  }
}

@media (min-width: 1320px) {

  // overriding bootstrap container-fluid class
  .container-fluid:not(.login-container) {
    max-width: 98%;
  }
}


/* Pagination Style */

.pagination {
  .page-item {
    &.arrow {
      height: 2.25rem;
      width: 36px;
      background: $primary-green-40;
      @include border-radius(0.188rem);
      cursor: pointer;
    }

    &.disabled {
      pointer-events: none;
    }
  }
}


/* table Style */

table.table {
  thead {
    th span.sortable {
      i {
        color: $text-dark;
        visibility: hidden;
        padding: 0.5rem 0rem 0.5rem 0rem;
      }

      &:hover {
        background: $hover_groups;
        cursor: pointer;

        i {
          visibility: visible;
        }
      }

      &.default,
      &.active {
        i {
          visibility: visible;
        }
      }
    }

    th {
      color: $text-dark;
      border-bottom: none;
      font-size: 0.75rem;
      font-weight: 700;
      color: $text-dark !important;
    }
  }

  tbody {
    font-size: 1rem;
    color: $text-dark !important;

    tr {
      border-bottom: 0.063rem solid $ghost_grey;

      &:last-child {
        border-bottom: none;
      }

      td {
        vertical-align: middle;
        border-bottom: 0.063rem solid $ghost_grey;
      }
    }
  }
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-disabled {
  cursor: not-allowed;
  // pointer-events: none !important;
}


/* Alerts */

.alert-panel {
  min-height: 50px;
  @include border-radius(0.1875rem);
  &.warning {
    background: linear-gradient(0deg, rgba(255, 169, 155, 0.35), rgba(255, 169, 155, 0.35)), #FFFFFF;
    border: solid 0.063rem $secondary-red-80;
  }

  &.success {
    background: $color_success_notifier_bg_color;
    border: solid 0.063rem $color_success_notifier_border;
  }

  .toast-body {
    padding: 0;
  }

  .img-container {
    &.warning {
      background-color: $secondary-red-80;
    }

    &.success {
      background-color: $color_success_notifier_bg_color;
    }

    .mh-material-icons-warning {
      font-size: 1.5rem;
      color: $white;
    }
  }

  .message {
    font-size: 0.875rem;
    color: $text-dark;
  }
}

.ui-notification {
  max-width: 100%;
  width: auto !important;
  z-index: 9999;

  &.alert-panel {
    &.killed {
      transition: opacity 5s ease-out;
      opacity: 0;
      display: none !important;
    }
  }
}

.tags {
  .tag {
    background: $color_tags_background;
    color: $color_gravel_approx;
    @include border-radius(0.125rem);

    >span {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .cancel {
      line-height: 1;
    }
  }
}

.alert-success {
  color: $text_active;
  @include border-radius(3px);
  box-shadow: 0 0.125rem 0.25rem 0 $black_50;
  border: solid 0.063rem $primary-blue-50;
  background-color: $color_alert_shadow;
}

.fix-table-layout {
  table-layout: fixed;
}

.link-disabled {
  pointer-events: none;
}

.alert .close {
  display: none;
}

.export-modal {
  .download-icon {
    width: 179px;
    height: 115px;
    cursor: pointer;
    @include border-radius(0.188rem);
    box-shadow: $box-shadow-popup-grey-30
  }

  .img-col {
    width: 32px;
    height: 32px;
  }
}

.no-transition {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}

.page-min-height {
  min-height: 400px;
}

.text-grey {
  color: $color_dim_grey;
}

.text-grey-2 {
  color: $color_storm_dust_approx;
}

.unauthorized-container {
  .hg-nav-container .hg-nav-bar .nav-item {
    .user-nav {
      padding-bottom: 0.875rem !important;
    }

    .shrink-user {
      padding-bottom: 0rem !important;
    }
  }

  .footer-label {
    margin-bottom: 1.875rem !important;
  }

  .text-secondary {
    color: $color_unauthorized_grey !important;
  }
}

.position-sticky {
  position: -webkit-sticky;
  /* Safari */
  position: sticky;
}

.logout-notify {
  .current-user-name {
    color: $primary-blue-50;
  }
}

.has-warning.error-message-background {
  background-color: $color_error_background;
  @include border-radius(3px);
}

.spinner-border {
  width: 3rem;
  height: 3rem;
}

.spinner-border.small {
  width: 1.5rem;
  height: 1.5rem;
}

.bg-danger {
  background-color: $secondary-red-90 !important;
}

@media (max-width: 481px) {
  #preAuthLoginContainer,
  #authLoginContainer {
    .auth0-lock-header {
      display: none;
    }

    .auth0-lock.auth0-lock {
      font-family: $primary-font;

      .auth0-lock-submit {
        @include border-radius(0.25rem);
        padding: 0;
        box-shadow: none !important;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: 1.5rem;
        transition: none !important;
        background-color: $primary-blue-sapphire !important;
        border: $primary-blue-sapphire 0.063rem solid;
        color: $pem-template-text-over-dark;

        &:hover {
          color: $pem-template-bg-white;
          background-color: $color_steel_blue !important;
          border: $color_steel_blue 0.063rem solid !important;
          box-shadow: none !important;
        }

        span {
          transition: none !important;
          transform: none;
          text-transform: none;
        }

        .auth0-label-submit svg {
          display: none;
        }
      }

      .auth0-lock-content {
        padding: 0;

        .auth0-lock-last-login-pane p {
          color: $color_paragraph;
          font-size: 1.25rem !important;
        }

        .auth0-lock-alternative-link {
          color: $color_star_dust_approx;
          font-size: 1rem;

          &:hover {
            color: $color-blue;
          }
        }
      }

      .auth0-lock-form p {
        margin-top: 0;
        text-align: center;
      }

      .auth0-loading-container {
        .auth0-loading {
          border-color: $white;
          border-left-color: $primary-blue-sapphire;
          left: calc(50% - 0.938rem);
        }
      }

      .auth0-global-message {
        &.auth0-global-message-error {
          text-align: left;
          background: $color_login_error_background;
          border: 0.063rem solid $color_login_border;
          color: $text-dark;
          border-radius: 0.25rem;

          .animated {
            &.fadeInUp::before {
              content: url('../images/legacy/error-octagon.svg');
              vertical-align: middle;
              padding-right: 0.625rem;
              line-height: 1;
            }

            > span {
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
      }
    }
  }
}

@media (min-width: 481px) {
  #preAuthLoginContainer,
  #authLoginContainer {
    .auth0-lock-header {
      display: none;
    }

    .auth0-lock-error-msg {
      width: 6.875rem;
    }

    .auth0-sso-notice-container {
      margin: 0 0 0.625rem 0;
    }

    .auth0-lock.auth0-lock {
      font-family: $primary-font;

      .auth0-lock-submit {
        @include border-radius(0.25rem);
        padding: 0;
        box-shadow: none !important;
        font-weight: 700;
        font-size: 1rem;
        line-height: 1;
        margin: 0 auto;
        margin-bottom: 1.5rem;
        width: 9.375rem;
        transition: none !important;
        background-color: $primary-blue-sapphire !important;
        border: $primary-blue-sapphire 0.063rem solid;
        color: $pem-template-text-over-dark;

        &:hover {
          color: $pem-template-bg-white;
          background-color: $color_steel_blue !important;
          border: $color_steel_blue 0.063rem solid !important;
          box-shadow: none !important;
        }

        span {
          transition: none !important;
          transform: none;
          text-transform: none;
        }

        .auth0-label-submit svg {
          display: none;
        }
      }

      .auth0-lock-content {
        padding: 0;

        .auth0-lock-last-login-pane p {
          color: $color_paragraph;
          font-size: 1.25rem !important;
        }

        .auth0-lock-alternative-link {
          color: $color_star_dust_approx;
          font-size: 1rem;

          &:hover {
            color: $color-blue;
          }
        }
      }

      .auth0-lock-form p {
        margin-top: 0;
        text-align: center;
      }

      .auth0-loading-container {
        .auth0-loading {
          border-color: $white;
          border-left-color: $primary-blue-sapphire;
          left: calc(50% - 0.938rem);
        }
      }

      .auth0-lock-widget {
        width: 24rem;
      }

      .auth0-global-message {
        &.auth0-global-message-error {
          text-align: left;
          background: $color_login_error_background;
          border: 0.063rem solid $color_login_border;
          color: $text-dark;
          border-radius: 0.25rem;

          .animated {
            &.fadeInUp::before {
              content: url('../images/legacy/error-octagon.svg');
              vertical-align: middle;
              padding-right: 0.625rem;
              line-height: 1;
            }

            > span {
              vertical-align: middle;
              display: inline-block;
            }
          }
        }
      }
    }

    .auth0-lock-center {
      padding: 0;
    }
  }
}

/*
   * Sticky Footer Behavior
   */
app-root {
  display: flex !important;
  flex-direction: column !important;
  height: 100% !important;
}


#app-header {
  width: 100%;
  position: fixed;
  top: 0;
  z-index: 999;
}

#app-content {
  padding-top: 7rem;
}

/*
   * Helper class for styling links/href elements over the template dark background.
   */
.mh-link-on-dark-bg,
.mh-link-on-dark-bg:hover,
.mh-link-on-dark-bg:focus {
  color: $pem-template-link-over-dark;
  text-decoration: none;
}

/*
   * Helper class for styling text over the template dark background.
   */
.mh-text-on-dark-bg {
  color: $pem-template-text-over-dark;
}

.mh-section-content {
  margin: 0 auto;
  width: 97%;
}

#app-content>.mh-section-content {
  background-color: $white;
}

.dropdown-item {
  font-family: $primary-font;
  font-size: 0.875rem;

  &:active {
    background-color: transparent !important;
  }
}