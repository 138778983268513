//app variables
// @import '/node_modules/bootstrap/scss/bootstrap.scss';
@import 'mh_color_system';
//app fonts
@import 'mh_fonts';
// MHC typography
@import 'mh_typography';
// MHC button
@import 'mh_button';
// MHC tabs
@import 'mh_tabs';
// MHC select
@import 'mh_select';
// popover styles
@import 'mh_popover.scss';

@import 'mh_main.scss';

@import 'mh_common.scss';

@import 'mh_form.scss';

@import 'mh_tooltip.scss';

@import 'mh_modal.scss';

.page-min-height {
    min-height: 400px;
}

.spinner-border {
    width: 3rem;
    height: 3rem;
}

.spinner-border.small {
    width: 1.5rem;
    height: 1.5rem;
}

ngb-typeahead-window {
  max-height: 200px;
  overflow-y: scroll;
  width: 350px;
}

.dropdown-list {
  max-height: 18.75rem;
  overflow-y: hidden;
}

.btn-primary {
  color: $pem-template-text-over-dark;
  background-color: $primary-blue-sapphire !important;
  border: $primary-blue-sapphire 0.063rem solid;
}


